@use '../mixin/media' as mixin_media;

.buttons-music {
  .row {
    margin: -1rem;
  }
}

@include mixin_media.media(">desktop-small") {
  .buttons-music {
    max-width: 75%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
